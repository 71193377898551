import React, { useState } from "react";
import { Row, Col, Card, Form, Alert } from "react-bootstrap";
import { ButtonPrimary } from "../../Buttons/index.js";
import authService from "../../../services/authService";
import Swal from "sweetalert2";

function FormLogin({ setIsLoading }) {
    const [formLogin, setFormLogin] = useState(true);
    const [formEmail, setFormEmail] = useState(false);
    const [formPassword, setFormPassword] = useState(false);
    const [user, setUser] = useState({
        email: "",
        password: "",
    });

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const startLoginProcess = () => {
        setError(false);
        setErrorMessage("");
        setIsLoading(true);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        startLoginProcess();

        try {
            const data = await authService.login(user.email, user.password);
            console.log("Login response data:", data);
            setIsLoading(false);
            if (data.success) {
                localStorage.setItem("isAuthenticated", "true");
                console.log("User authenticated, redirecting to /dashboard");
                window.location.href = "/dashboard";
            } else {
                console.log("Login failed:", data.message);
            }
        } catch (err) {
            setIsLoading(false);
            setError(true);
            setErrorMessage(
                err.response && err.response.data
                    ? err.response.data.message
                    : "Estamos enfrentando problemas para realizar essa ação. Por gentileza tente novamente mais tarde."
            );
            console.error("Login error:", err);
        }
    };

    const handleSubmitEmail = async (event) => {
        event.preventDefault();
        startLoginProcess();

        await authService
            .emailInfo(user)
            .then((res) => {
                setIsLoading(false);
                Swal.fire(
                    "Solicitação encaminhada!",
                    "Se o CPF estiver associado a uma conta, um e-mail foi enviado.",
                    "success"
                ).then(() => {
                    window.location.href = "/login";
                });
            })
            .catch((err) => {
                setIsLoading(false);
                Swal.fire(
                    "Solicitação encaminhada!",
                    "Se o CPF estiver associado a uma conta, um e-mail foi enviado.",
                    "success"
                ).then(() => {
                    window.location.href = "/login";
                });
            });
    };

    const handleSubmitPassword = async (event) => {
        event.preventDefault();
        startLoginProcess();

        await authService
            .passwordResetToken(user)
            .then((res) => {
                setIsLoading(false);
                Swal.fire(
                    "Solicitação encaminhada!",
                    "Se o e-mail estiver associado a uma conta, um e-mail foi enviado.",
                    "success"
                ).then(() => {
                    window.location.href = "/login";
                });
            })
            .catch((err) => {
                setIsLoading(false);
                Swal.fire(
                    "Solicitação encaminhada!",
                    "Se o e-mail estiver associado a uma conta, um e-mail foi enviado.",
                    "success"
                ).then(() => {
                    window.location.href = "/login";
                });
            });
    };

    return (
        <Card className="card-form card-login">
            <Row className="m-0">
                {formLogin && (
                    <Col xs={12} className="padding-card">
                        <h2 className="text-uppercase font-semi-bold mb-0">
                            login
                        </h2>
                        <p className="font-light color-dark-gray mb-4">
                            Digite suas informações e acesse sua conta.
                        </p>
                        <Form onSubmit={handleSubmit}>
                            <Row className="mb-4">
                                <Col xs={12}>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            E-mail
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Digite seu E-MAIL"
                                            onChange={handleInputChange}
                                            value={user.email}
                                            required
                                        />
                                        {/* <div className="d-flex justify-content-end">
                                            <Form.Text
                                                className="color-light-gray small-info"
                                                onClick={() => {
                                                    setFormEmail(true);
                                                    setFormLogin(false);
                                                }}
                                            >
                                                Esqueceu o seu e-mail?
                                            </Form.Text>
                                        </div> */}
                                    </Form.Group>
                                </Col>
                                <Col xs={12} className="my-4">
                                    <Form.Group>
                                        <Form.Label className="text-uppercase">
                                            Senha
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            placeholder="Digite sua Senha"
                                            onChange={handleInputChange}
                                            value={user.password}
                                            required
                                        />
                                        {/* <div className="d-flex justify-content-end">
                                            <Form.Text
                                                className="color-light-gray small-info"
                                                onClick={() => {
                                                    setFormPassword(true);
                                                    setFormLogin(false);
                                                }}
                                            >
                                                Esqueceu a seu senha?
                                            </Form.Text>
                                        </div> */}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    xs={12}
                                    className="d-flex flex-column flex-md-row justify-content-center justify-content-md-end"
                                >
                                    <ButtonPrimary
                                        type="submit"
                                        btnText="ENTRAR NA MINHA CONTA"
                                        className={"w-100"}
                                    />
                                </Col>
                                {error && (
                                    <Col
                                        xs={12}
                                        className="mt-3 mb-0 text-center small"
                                    >
                                        <Alert variant={"danger"}>
                                            <strong>{errorMessage}</strong>
                                        </Alert>
                                    </Col>
                                )}
                            </Row>
                        </Form>
                    </Col>
                )}
                {formEmail && (
                    <Col xs={12} className="padding-card">
                        <Row>
                            <Col xs={12} className="d-flex justify-content-end">
                                <h6
                                    className="c-pointer text-uppercase mb-0"
                                    onClick={() => {
                                        setFormLogin(true);
                                        setFormEmail(false);
                                    }}
                                >
                                    Voltar
                                </h6>
                            </Col>
                        </Row>
                        <h3 className="text-uppercase font-semi-bold mb-0">
                            RECUPERAR O E-MAIL CADASTRADO
                        </h3>
                        <p className="font-light color-dark-gray mb-4">
                            Informe o CPF para que possamos verificar em nosso
                            sistema o e-mail associado a ele.
                        </p>
                        <Form onSubmit={handleSubmitEmail}>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            CPF
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="cpf"
                                            placeholder="Digite o seu CPF"
                                            onChange={handleInputChange}
                                            value={user.cpf}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col
                                    xs={12}
                                    className="d-flex flex-column flex-md-row justify-content-center justify-content-md-end"
                                >
                                    <ButtonPrimary
                                        type="submit"
                                        btnText="RECUPERAR MEU E-MAIL"
                                        className={"w-100"}
                                    />
                                </Col>
                                {error && (
                                    <Col
                                        xs={12}
                                        className="mt-3 mb-0 text-center small"
                                    >
                                        <Alert variant={"danger"}>
                                            <strong>{errorMessage}</strong>
                                        </Alert>
                                    </Col>
                                )}
                            </Row>
                        </Form>
                        <small>
                            <strong className="me-1 text-danger fw-bold">
                                *
                            </strong>
                            Se você tem certeza de que realizou o cadastro, mas
                            o sistema não encontrou os seus dados, é possível
                            que seu CPF tenha sido cadastrado incorretamente no
                            momento do registro. Para resolver esse problema,
                            entre em contato com nossa equipe.{" "}
                            <a
                                href="https://api.whatsapp.com/send?phone=5541999822211"
                                className="fw-bold"
                                title=""
                                target="_blank"
                                rel="noreferrer"
                            >
                                Falar com equipe!
                            </a>
                        </small>
                    </Col>
                )}
                {formPassword && (
                    <Col xs={12} className="padding-card">
                        <Row>
                            <Col xs={12} className="d-flex justify-content-end">
                                <h6
                                    className="c-pointer text-uppercase mb-0"
                                    onClick={() => {
                                        setFormLogin(true);
                                        setFormPassword(false);
                                    }}
                                >
                                    Voltar
                                </h6>
                            </Col>
                        </Row>
                        <h3 className="text-uppercase font-semi-bold mb-0">
                            REDEFINIR MINHA SENHA
                        </h3>
                        <p className="font-light color-dark-gray mb-4">
                            Digite o e-mail cadastrado para receber um e-mail
                            com as instruções para redefinir sua senha.
                        </p>
                        <Form onSubmit={handleSubmitPassword}>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group className="mb-2">
                                        <Form.Label className="text-uppercase">
                                            E-mail
                                            <sup className="ms-1 text-danger fw-bold">
                                                *
                                            </sup>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="email"
                                            placeholder="Digite seu e-mail"
                                            onChange={handleInputChange}
                                            value={user.email}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col
                                    xs={12}
                                    className="d-flex flex-column flex-md-row justify-content-center justify-content-md-end"
                                >
                                    <ButtonPrimary
                                        type="submit"
                                        btnText="RECUPERAR MEU E-MAIL"
                                        className={"w-100"}
                                    />
                                </Col>
                                {error && (
                                    <Col
                                        xs={12}
                                        className="mt-3 mb-0 text-center small"
                                    >
                                        <Alert variant={"danger"}>
                                            <strong>{errorMessage}</strong>
                                        </Alert>
                                    </Col>
                                )}
                            </Row>
                        </Form>
                        <small>
                            <strong className="me-1 text-danger fw-bold">
                                *
                            </strong>
                            Lembre-se de verificar a caixa de spam do seu
                            e-mail, pois às vezes nossa mensagem pode parar lá.
                            Caso não se lembre do e-mail usado no cadastro,{" "}
                            <strong
                                className="c-pointer"
                                onClick={() => {
                                    setFormPassword(false);
                                    setFormEmail(true);
                                }}
                            >
                                clique aqui para recuperá-lo
                            </strong>
                            .
                        </small>
                    </Col>
                )}
            </Row>
        </Card>
    );
}

export default FormLogin;
