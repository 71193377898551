import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Loading from "../../components/Loading";
import FormPasswordReset from "../../components/Forms/PasswordReset";

function PasswordReset() {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = "Clube Cult - MS - Web APP - Alteração de Senha";
        if (localStorage.getItem("isAuthenticated")) {
            window.location.href = "/dashboard";
        }
    }, []);

    return (
        <section className="section-height-100 center-items bg-color-primary">
            <Container>
                <Row>
                    <Col xs={12} className="d-flex justify-content-center">
                        <FormPasswordReset setLoading={setLoading} />
                    </Col>
                </Row>
            </Container>
            <Loading show={loading} />
        </section>
    );
}

export default PasswordReset;
