import React, { createContext, useContext, useState, useEffect } from "react";
import authService from "../services/authService";
import Loading from "../components/Loading";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadUser = async () => {
            try {
                const userInfo = await authService.getUserInfo();
                if (userInfo && userInfo.user) {
                    setUser(userInfo.user);
                } else {
                    throw new Error("Usuário não autenticado");
                }
            } catch (error) {
                localStorage.clear();
                setUser(null);
            } finally {
                setIsLoading(false);
            }
        };

        loadUser();
    }, []);

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            {!isLoading ? children : <Loading show={isLoading} />}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
