import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import FormLogin from "../../components/Forms/Login";
import Loading from "../../components/Loading";

function Login() {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = "Clube Cult - Web APP";

        const checkAuth = async () => {
            setIsLoading(true);
            if (localStorage.getItem("isAuthenticated") === "true") {
                window.location.href = "/dashboard";
            }
            setIsLoading(false);
        };

        checkAuth();
    }, []);

    return (
        <div className="bg-color-primary">
            <Row className="min-vh-100 m-0">
                <Col md={6} className="p-0 d-none d-md-flex bg-login"></Col>
                <Col
                    xs={12}
                    md={6}
                    className="d-flex justify-content-center align-items-center"
                >
                    <FormLogin setIsLoading={setIsLoading} />
                </Col>
            </Row>
            <Loading show={isLoading} />
        </div>
    );
}

export default Login;
